import React from "react";
import { Typography, Box } from "@mui/material";

const MyToolbarCenter = ({ title, children, left }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between", // This will space out the title and children if both are present
        alignItems: "center", // Vertically center align items in the container
      }}
    >
      {title && (
        <Typography
          sx={{
            // Conditionally apply flexGrow: If left is true or children are not present, don't grow the title.
            flexGrow: left || !children ? 0 : 1,
            // Center the text if left is not true and children are not present
            textAlign: left || !children ? "left" : "center",
          }}
          variant="h6"
        >
          {title}
        </Typography>
      )}
      {children && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center", // Horizontally center the children
            alignItems: "center", // Vertically center the children (useful if flexWrap wraps items)
            width: "100%", // Ensure the Box takes the full width if you want children to be centered in the whole container
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default MyToolbarCenter;
