import React from "react";
import { useParams, useLocation } from "react-router-dom";
import * as QueryString from "query-string";
import { Page } from "advancement-solutions-components/dist/components";
import Crumbs from "./Crumbs";
import Chapters from "./Chapters";
import { useGetChapters, useGetTopic, useTopicReset } from "hooks/api";
import Loader from "components/Loader";
import SkeletonList from "components/SkeletonList";
import TopicRating from "components/TopicRating";
import { PageContainer } from "advancement-solutions-components/dist/components";
import MyToolbar from "components/MyToolbar";
import { useProvideBranding } from "providers/ProvideBranding";
import { Typography, IconButton, Tooltip, Button } from "@mui/material";
import ResetIcon from "@mui/icons-material/Restore";
import InfoIcon from "@mui/icons-material/InfoRounded";
import NoChaptersWarning from "./NoChaptersWarning";
import { handlingConditions } from "../../utils";
import MyToolbarLeft from "components/MyToolbarLeft";
import MyToolbarRight from "components/MyToolbarRight";
import MyToolbarCenter from "components/MyToolbarCenter";
import { Box } from "@mui/system";
import { useGetTopicDeckTotals } from "hooks/api";
import DeckProgress from "components/DeckProgress";
import { Link } from "react-router-dom";
import * as routes from "routes";

const ChapterPage = ({ user }) => {
  const { group, topicId } = useParams();
  const [topicDeckTotals] = useGetTopicDeckTotals(topicId);
  const location = useLocation();
  const { handling } = QueryString.parse(location.search);
  const [topic, isLoadingTopic, topicError] = useGetTopic(group, topicId);
  const [chapters, isLoadingChapters, chaptersError] = useGetChapters(
    group,
    topicId
  );
  const [reset] = useTopicReset(group, topicId);
  const { rawString } = useProvideBranding(process.env.REACT_APP_BRAND);
  const supportEmail = rawString("app.support.email");
  const handlingDescription =
    handling && handlingConditions[handling].description({ supportEmail });

  return (
    <Page user={user}>
      <Crumbs group={group} topic={topic} isLoading={isLoadingTopic} />
      {topic && (
        <MyToolbar title={topic.title}>
          <MyToolbarLeft>
            <Tooltip title="Reset">
              <IconButton
                color="primary"
                onClick={() => reset(topicId)}
                size="large"
              >
                <ResetIcon />
              </IconButton>
            </Tooltip>
          </MyToolbarLeft>
          <MyToolbarCenter center>
            {process.env.REACT_APP_BRAND === "cert" && (
              <Button
                component={Link}
                to={{
                  pathname: routes.MOCK_EXAM,
                  state: { topic: topic },
                }}
              >
                Start Exam
              </Button>
            )}
          </MyToolbarCenter>
          <MyToolbarRight>
            <TopicRating topic={topic} />
          </MyToolbarRight>
        </MyToolbar>
      )}
      {handling && (
        <PageContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: 2,
              marginTop: 2,
              marginBottom: 4,
            }}
          >
            <InfoIcon
              size="large"
              sx={{
                margin: 2,
                fontSize: 40,
                color: "info.main",
              }}
            />
            <Typography align="center">{handlingDescription}</Typography>
          </Box>
        </PageContainer>
      )}
      <PageContainer>
        <Loader
          datas={[chapters]}
          data={chapters}
          isLoadings={[isLoadingTopic, isLoadingChapters]}
          LoadingComponent={SkeletonList}
          EmptyComponents={[NoChaptersWarning]}
          errors={[topicError, chaptersError]}
        >
          {(data) => (
            <>
              <DeckProgress totals={topicDeckTotals} />
              <Chapters
                locked={Boolean(handling)}
                group={group}
                chapters={data}
              />
            </>
          )}
        </Loader>
      </PageContainer>
    </Page>
  );
};

export default ChapterPage;
