/*
  good url for working with coordinated colors https://m2.material.io/inline-tools/color/
*/
import { commonColors, commonDarkColors } from "../commonTheme";

const lightBrandColors = {
  primary: {
    main: "#153e70",
  },
  secondary: {
    main: "#257eaa",
  },
  info: {
    main: "#03a9f4",
    contrastText: "#ffffff",
  },
};

const darkBrandColors = {
  primary: {
    main: "#3d87be",
  },
  secondary: {
    main: "#39b0de",
  },
  info: {
    main: "#03a9f4",
    contrastText: "#ffffff",
  },
};

const light = {
  ...lightBrandColors,
  ...commonColors,
};

const dark = {
  ...darkBrandColors,
  ...commonColors,
  ...commonDarkColors(),
};

export default { light, dark };
