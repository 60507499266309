import { useState, useEffect } from "react";
import { useApi } from "providers/ProvideApi";
import { useAlert } from "advancement-solutions-components/dist/providers";
import { wrapError } from "../../utils";
import useGet from "./useGet";
import useHead from "./useHead";

export const useGetRates = () => {
  const { api } = useApi();
  const [data, isLoading, error] = useGet(api, `/public/rates`, []);
  return [data && data.rates, isLoading, error];
};

export const useGetIndustries = () => {
  const { api } = useApi();
  const [data, isLoading, error] = useGet(api, `/public/industries`, []);
  return [data && data.industries, isLoading, error];
};

export const useGetUser = () => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/user`);
  return [data && data.user, isLoading, error];
  // just a comment to make a change.
};

export const useGetUserStats = () => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/user/stats`);
  return [data && data.stats, isLoading, error];
};

export const useGetUserAchievements = () => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/user/achievements`);
  return [data && data.achievements, isLoading, error];
};

export const useGetUserChallenges = () => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/user/challenges`);
  return [data && data.challenges, isLoading, error];
};

export const useGetUserSubscription = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = async () => {
    try {
      const res = await authApi.get(`/api/user/subscription`);
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [refresh, data, isLoading, error];
};

export const useGetGroup = (group) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/p/user/${group}`);
  return [data && data, isLoading, error];
};

export const useGetTopics = (group) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/${group}/topics`,
    []
  );
  return [data && data.topics, isLoading, error];
};

export const useGetRecentTopics = (brand, limit) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/topics/recents?brand=${brand}&limit=${limit}`,
    []
  );
  return [data && data.topics, isLoading, error];
};

export const useGetTopic = (group, topicId) => {
  const brand = process.env.REACT_APP_BRAND;
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/${group}/topics/${topicId}?brand=${brand}`
  );
  return [data && data.topic, isLoading, error];
};

export const useGetTopicDeckTotals = (topicId) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/topics/${topicId}/deck/totals`
  );
  return [data && data.totals, isLoading, error];
};

export const useGetChapters = (group, topicId) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/${group}/topics/${topicId}/chapters`,
    []
  );
  return [data && data.chapters, isLoading, error];
};

export const useGetChapter = (group, topicId, chapterId) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/${group}/topics/${topicId}/chapters/${chapterId}`
  );
  return [data && data.chapter, isLoading, error];
};

export const useGetChapterDeckTotals = (topicId, chapterId) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/topics/${topicId}/chapters/${chapterId}/deck/totals`
  );
  return [data && data.totals, isLoading, error];
};

export const useGetPodcasts = (topicId, chapterId) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/topics/${topicId}/chapters/${chapterId}/podcasts`,
    []
  );
  return [data && data.podcasts, isLoading, error];
};

export const useGetExamQuestions = (topicId) => {
  const { authApi } = useApi();
  const url = topicId
    ? `/api/p/user/mock-exam/questions?topicId=${topicId}`
    : `/api/p/user/mock-exam/questions`;
  const [data, isLoading, error] = useGet(authApi, url, []);
  return [data && data.questions, isLoading, error];
};

export const useGetFocusedQuestions = (focus) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/questions/${focus}`,
    []
  );
  return [data && data.questions, isLoading, error];
};

export const useGetFocusedModes = (focus) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/questions/${focus}`,
    {}
  );
  return [data && { modes: data.modes, total: data.total }, isLoading, error];
};

export const useGetQuestions = (topicId, chapterId) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/user/topics/${topicId}/chapters/${chapterId}/questions`,
    []
  );
  return [data && data.questions, isLoading, error];
};

export const useGetQuestionsHead = (topicId, chapterId) => {
  const { authApi } = useApi();
  const [headers, isLoading, error] = useHead(
    authApi,
    `/api/p/user/topics/${topicId}/chapters/${chapterId}/questions`,
    []
  );
  return [headers, isLoading, error];
};

export const useGetQuestion = (questionId) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(
    authApi,
    `/api/p/questions/${questionId}`
  );
  return [data && data.question, isLoading, error];
};

export const useAnswerQuestion = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const answer = async (questionId, deck, answer, answerContext) => {
    try {
      setIsLoading(false);
      const res = await authApi.post(
        `/api/p/user/questions/${questionId}/answered`,
        { questionId, deck, answer, answerContext }
      );
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [answer, data && data, isLoading, error];
};

export const useMoveQuestion = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const move = async (questionId, deck) => {
    try {
      setIsLoading(false);
      const res = await authApi.post(
        `/api/p/user/questions/${questionId}/deck`,
        { deck }
      );
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [move, data && data, isLoading, error];
};

export const useTopicRating = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getRating = async (topicId) => {
    try {
      setIsLoading(false);
      const res = await authApi.get(`/api/p/user/topics/${topicId}/rating`);
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  const setRating = async (topicId, rating) => {
    try {
      setIsLoading(false);
      const res = await authApi.put(`/api/p/user/topics/${topicId}/rating`, {
        rating,
      });
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [getRating, setRating, data && data.rating, isLoading, error];
};

export const useAnswerQuestions = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);

  const answer = async (answereds) => {
    try {
      setIsLoading(false);
      const res = await authApi.post(`/api/p/user/questions/answered`, [
        ...answereds,
      ]);
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [answer, data && data, isLoading, error];
};

export const useMoveQuestions = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const move = async (decks) => {
    try {
      setIsLoading(false);
      const res = await authApi.post(`/api/p/user/questions/deck`, {
        ...decks,
      });
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [move, data && data, isLoading, error];
};

export const useChapterRating = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getRating = async (topicId, chapterId) => {
    try {
      setIsLoading(false);
      const res = await authApi.get(
        `/api/p/user/topics/${topicId}/chapters/${chapterId}/rating`
      );
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  const setRating = async (topicId, chapterId, rating) => {
    try {
      setIsLoading(false);
      const res = await authApi.put(
        `/api/p/user/topics/${topicId}/chapters/${chapterId}/rating`,
        { rating }
      );
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [getRating, setRating, data && data.rating, isLoading, error];
};

export const useChapterReset = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { send } = useAlert();

  const reset = async (topicId, chapterId) => {
    try {
      setIsLoading(false);
      const res = await authApi.delete(
        `/api/p/user/topics/${topicId}/chapters/${chapterId}/deck`
      );
      send({
        message: `Chapter reset.`,
        severity: "success",
      });
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [reset, data && data.rating, isLoading, error];
};

export const useTopicReset = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { send } = useAlert();

  const reset = async (topicId) => {
    try {
      setIsLoading(false);
      const res = await authApi.delete(`/api/p/user/topics/${topicId}/deck`);
      send({
        message: `Topic reset.`,
        severity: "success",
      });
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [reset, data && data.rating, isLoading, error];
};

export const useCauseError = (code) => {
  const { authApi } = useApi();
  const [data, isLoading, error] = useGet(authApi, `/api/error/${code}`);
  return [data, isLoading, error];
};

export const useMockExamStorage = () => {
  const { authApi } = useApi();
  const [data, setData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getExam = async () => {
    try {
      setIsLoading(true);
      const res = await authApi.get(`/api/p/user/mock-exam`);
      setData(res.data);
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  const setExam = async (exam) => {
    try {
      setIsLoading(false);
      exam && (await authApi.put(`/api/p/user/mock-exam`, { exam }));
      setData({ exam });
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [getExam, setExam, data && data.exam, isLoading, error];
};

export const useProgressStorage = () => {
  const { authApi } = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const getProgress = async () => {
    try {
      setIsLoading(true);
      const res = await authApi.get(`/api/user/progress`);
      return res.data;
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  const setProgress = async (progress) => {
    try {
      setIsLoading(false);
      await authApi.put(`/api/user/progress`, { progress });
      return true;
    } catch (e) {
      setError(wrapError(e));
    } finally {
      setIsLoading(false);
    }
  };
  return [getProgress, setProgress, isLoading, error];
};
