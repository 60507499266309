export const commonColors = {
  easy: { main: "#2e7d32", light: "#81c784", contrastText: "#ffffff" },
  normal: { main: "#0288d1", light: "#4fc3f7", contrastText: "#ffffff" },
  hard: { main: "#d32f2f", light: "#e57373", contrastText: "#ffffff" },
  tertiary: {
    main: "#ede9e0",
  },
};

export const commonDarkColors = (action = "#0288d1") => ({
  text: {
    primary: "#ffffff",
    secondary: "rgba(255, 255, 255, 0.8)",
    disabled: "rgba(255, 255, 255, 0.7)",
  },
  action: {
    active: action,
    hover: "rgba(255, 255, 255, 0.3)",
    selected: "rgba(255, 255, 255, 0.6)",
  },
  background: {
    paper: "#343434",
    default: "#232323",
  },
});
