import { Typography } from "@mui/material";
import React, { createContext, useContext } from "react";
import { messages as bmr } from "./bmr";
import { images as bmrImages } from "./bmr/images";

const images = {
  bmr: bmrImages,
};

const brandingContext = createContext();

const getNested = (obj, ...args) => {
  return args.reduce((obj, level) => obj && obj[level], obj);
};

const ProvideBranding = ({ brand, children }) => {
  const branding = useProvideBranding(brand);
  return (
    <brandingContext.Provider value={branding}>
      {children}
    </brandingContext.Provider>
  );
};

export const useProvideBranding = (brand) => {
  const rawString = (id) => {
    const messages = { bmr };
    const value = getNested(messages, brand, id);
    return value || "";
  };

  const rawImage = (id) => {
    return images[brand][id];
  };

  return {
    rawString,
    rawImage,
  };
};

export const BrandedTypography = ({ id, ...rest }) => {
  const { rawString } = useBranding();
  const value = rawString(id);
  return <Typography {...rest}>{value}</Typography>;
};

export const useBranding = () => useContext(brandingContext);
export default ProvideBranding;
