import React, { Fragment } from "react";
import { useGetGroup } from "hooks/api";
import { Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { Box } from "@mui/system";

const groupLookups = {
  pmk: "PMK-EE",
  "profile-sheet": "Profile Sheet",
  bib: "BIB",
  credential: "Credentials",
  warfare: "Warfare",
  qualification: "CLEP®",
  certification: "Certifications",
};

const GroupBanner = ({ group }) => {
  const [g] = useGetGroup(group);
  const brand = process.env.REACT_APP_BRAND;
  return (
    <Box
      sx={{
        margin: 1,
      }}
    >
      {g ? (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Fragment>
              <Typography
                variant="h6"
                sx={{
                  marginRight: 1,
                }}
                align="center"
              >
                {groupLookups[group]}
              </Typography>
              {brand === "bmr" && (
                <Typography
                  variant="h6"
                  sx={{
                    marginRight: 1,
                  }}
                  align="center"
                >
                  :{g.rate}
                </Typography>
              )}
            </Fragment>
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography variant="h6">
              <Skeleton width={150} />
            </Typography>
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default GroupBanner;
